import { helpBaseURL } from 'common/constants';
import { default as coinsConfig, coinExplorerConfig } from './coins';
import { pageConfig, getPageConfig } from './pageConfig';
export * from './localeConfig';

const defaultPageSizeOptions = ['10', '25', '50', '100'];

const defaultListPage = {
  page: 1,
  page_size: 50,
};

const timeRangeOpitons = [
  {
    value: '1d',
    name_zh: '1天',
    name_en: '1D',
    name_es: '1 día',
    name_ru: '1 день',
    name_de: '1 Tag',
    name_fr: '1 Jour',
    name_ja: '1 日',
    name_ko: '1 일',
    name_tr: '1 Gün',
  },
  {
    value: '1h',
    name_zh: '1小时',
    name_en: '1H',
    name_es: '1 hora',
    name_ru: '1 час',
    name_de: '1 Stunde',
    name_fr: '1 Heure',
    name_ja: '1 時間',
    name_ko: '1 시간',
    name_tr: '1 Saat',
  },
];

// Announcement query url from helpcenter
const announcementQueryURL = {
  baseURL: process.env.NODE_ENV === 'development' ? '/zendesk/' : helpBaseURL,
  'zh-CN':
    '/api/v2/help_center/zh-cn/categories/360001217931/articles.json?sort_by=created_at&sort_order=desc',
  'en-US':
    '/api/v2/help_center/zh-cn/categories/360001217931/articles.json?sort_by=created_at&sort_order=desc',
};

// chart colors map
const chartColors = ['#1A7EE3', '#8EA6BF', '#2ec7c9', '#d87a80'];
const chartAreaColors = [
  {
    color: {
      type: 'linear',
      x: 0,
      y: 1,
      x2: 0,
      y2: 0,
      colorStops: [
        {
          offset: 0,
          color: 'rgba(71, 150, 228, 0.18)',
        },
        {
          offset: 1,
          color: 'rgba(71, 150, 228, 1)',
        },
      ],
    },
    opacity: 0.1,
  },
];

const ltcMergeCoin = ['doge', 'bel', 'lky', 'pep'];
export {
  coinsConfig,
  coinExplorerConfig,
  defaultPageSizeOptions,
  defaultListPage,
  timeRangeOpitons,
  announcementQueryURL,
  chartColors,
  chartAreaColors,
  pageConfig,
  getPageConfig,
  ltcMergeCoin,
};
