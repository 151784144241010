import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} マイニング收益',
      accountEarning: 'アカウント收益',
      active: 'アクティブ',
      activeMiner: 'アクティブマシン',
      amoutPaid: '支払済',
      announcement: '通知',
      cannotConnect: '接続できませんか?',
      urlConfigGpu: ' 通常のGPUマイニングに対応',
      urlConfigNicehash: ' Nicehashに最適化',
      urlConfigGpuDcr: ' アリマイニング,DR3，Claymore，gominerに対応',
      urlConfigNicehashDcr:
        ' すでにNicehash，芯动科技等のマイニングマシンをサポートしています',
      customerTitle: 'アフターサービス',
      dailyHashrate: 'デイリーハッシュレート',
      dashboard: 'ダッシュボード',
      earnMore: '他のプールよりもCloverPoolプールがどれだけ多く稼いでいるか',
      earning: '{coinType} 収益',
      eventConnected: '接続済',
      eventDisconnected: '接続なし',
      eventMiner: 'マイニングマシン',
      eventName: '件のメッセージ',
      eventSkip: 'スキップ済',
      eventTitle: 'イベント通知',
      eventlatestLogs: '以下は過去のイベントです',
      hastrateChart: 'ハッシュレートチャート',
      history: '支払記録',
      hour: '{n} 時間',
      hours: '時間',
      inActive: '非アクティブ',
      lastPaymentTime: '前回の支払い時間',
      markAllRead: 'すべて既読としてマーク',
      minerContent:
        'マイニングソフトウェア構成インターフェイスに入り,子アカウント（パスワードを空欄のままにすることをお勧めします）とマイニングアドレスを入力し,保存後exeプログラムを実行すると,マイニングマシンは1分以内にプールのWebサイトページを自動的に追加します。 ',
      minerNameStand:
        'マイニングマシン名のルールは,子アカウント+英文+番号です。たとえば,子アカウントが{sub_account}の場合,マイニングマシンの名前を{sub_account} .001,{sub_account} .002と設定できます。 このように,1つのマイニングマシン名は１つのマイニングマシンに対応します。 ',
      minerName:
        'マイニングマシン設定例:"{sub_account}.001", "{sub_account}.002".',
      miner: 'マイニングマシン',
      miner1: 'I 類マイニングマシン',
      miner2: 'II 類マイニングマシン',
      miners: 'マイニングマシン数',
      miningAddress: 'マイニングアドレス',
      miningEarnings: '{coinType} ハッシュレート収益',
      minute: '分',
      more: 'もっと見る',
      networkDiff: '現在の難易度',
      networkHashrate: 'ネットワークハッシュレート',
      networkStatus: 'ネットワークステータス',
      nextDifficult: '次回難易度の予測',
      noMiner:
        'プールにマイニングマシンを追加してから,マイニングを開始する必要があります。 CloverPoolプールを使用することをお勧めします ',
      btcTools: 'プロキシソフトウェア。',
      noEvent: 'イベントの通知なし!',
      nowHashrate: '現在のハッシュレート',
      pendingPayouts: '支払いを確認する',
      poolHashrate: 'プールハッシュレート',
      realHashrate: '{  coinType} リアルタイムハッシュレート',
      realtime: 'リアルタイム',
      timeRemain: '残り時間',
      title: 'ダッシュボード - CloverPool',
      today: '今日の見積もり',
      unpaid: '残高',
      yesterday: '昨日の収益',
      yesterdayEarnTip: 'UTC時間の前日の0：00〜24：00の収益を指します。',
      todayEarnTip: 'SHA256ハッシュレートBTCマイニング収入の見積もり',
      charts: {
        hashrate: 'ハッシュレート',
        minerCharts: 'マインニグマシーンチャート',
        myCharts: 'マイハッシュレートチャート',
        poolCharts: 'プールハッシュレートチャート',
        reject: '拒絶率',
        time: '時間',
      },
      yesterday_earn: '昨日の收益',
      yesterday_hashhrate:
        '昨日の有效なハッシュレート(拒绝されたものは含みません)',
      earnings_per_100T: '100Tあたりの収益に相当',
      //義務声明
      statement:
        '* お客様は,当社が提供または提案する以外の方法でこのサービスを使用する（または使用を試みる）ことはできません。またお客様は違法若しくは違法な方法でこのサービスを使用しない（または使用を試みない）こと,違法行為を支援しないこと,違法行為を目的として本サービスを利用しないことを約束し保証するものとします。 そしてお客様はこのウェブサイトにアクセスしてこのサービスに登録することにより,あなたはあなたの所在する司法管轄区の法律に従ってこのサービスを合法的に受けることができる人であることを約束し保証していることを示すものとします。 ',
      // 收益の見積もり
      estimatedEarnings: {
        title: 'ハッシュレート収益の見積もり',
        hours24Earnings: '過去24時間の見積もり',
        tip24: '過去24時間の平均マイニング難易度に基づく計算',
        realTimeEarnings: 'リアルタイム予測',
        realTimeEarningsTip: '最新のマイニング難易度に基づく計算',
      },
    },
    subAccount: {
      title: '子アカウント作成 - CloverPool(formerly BTC.com)',
      createWorker: '子アカウントの作成',
      noNode: 'まだノードを選択していません！',
      noCoinType: 'まだコインの種類を選択していません！',
      selectRegion: '地域を選択',
      setWorker: '子アカウントの名称を設定',
      enterWorker: '子アカウントの名称を入力してください',
      enterAddress: 'お支払い先住所を入力してください',
      coinTypeEx:
        'CloverPoolはマイニングをサポートするようになりました。{supportCoins}より多くのコインがまもなく発売されますので,ご期待ください。',
      regionEx:
        'CloverPoolは世界中にサーバーを展開しているので,最高のエクスペリエンスを得るために最も近いサーバーを選択します。',
      workerEx:
        '設定した子アカウントは,選択した地域に関連付けされます。 最大2000の子アカウントを設定できます。 この子アカウント名とマイニングマシン番号は,「{name} .001」,「{name} .002」のように,マイニングマシン設定ページのマイニングマシン名となります。 ',
      workerCaution:
        '*同じ子アカウントは異なる地域のマイニングをサポートしておらず,現在の地域で作成したサブアカウントは他の地域には存在しないことに注意してください。 別の地域でマイニングマシンを所有している場合は,対応する地域で子アカウントを再作成してください。 ',
      setAddress: '引き出しアドレスを入力してください',
      save: '作成してマイニング',
      verifyWorker:
        '子アカウントは3〜20文字または数字の組み合わせのみをサポートします',
      option: 'オプション',
      'optional-BTC':
        '注:ビットコインキャッシュ（BCH）の古い形式のアドレスとビットコイン（BTC）のアドレスは似ているので,コインの紛失を防ぐために,慎重に入力してください。 ',
      'optional-BCH':
        '注:ビットコインキャッシュ（BCH）の古い形式のアドレスとビットコイン（BTC）のアドレスは似ているので,コインの紛失を防ぐために,慎重に入力してください。新しいフォーマットのアドレスを使用することをお勧めします ',
      'optional-UBTC':
        '注:ビットコインキャッシュ（BCH）の古い形式のアドレスはユナイテッドビットコイン（UBTC）のアドレスに似ています。コインを紛失しないように,慎重に入力してください。 ',
      'optional-SBTC':
        '注:ビットコインキャッシュ（BCH）の古い形式のアドレスはスーパービットコイン（SBTC）のアドレスに似ています。コインを紛失しないように,慎重に記入してください。 ',
      'optional-coin':
        '注:コインを紛失しないように,{coin_type}アドレスを慎重に入力してください。 ',
      selectCoinType: '通貨を選択',
      automatic: '自動生成',
      recommandWallet: 'CloverPoolウォレットを使用することをお勧めします',
      withdrawToExchange: '取引所に引き出す',
      withdrawToWallet: '「ウォレットに引き出す」',
    },
    tools: {
      // title: 'ツール - CloverPool',
      // tools: 'ツール',
      // fppsPps: 'FPPSと従来のPPS動的比較ツール',
      // aboutFpps: 'FPPSは従来のPPSよりどこが優れていますか？ ',
      //グラフ:'動的データ　一目瞭然',
      // watchCharts: 'クリックして比較',
      // miniCalc: 'マイニング計算機',
      // miniCalcExpress: '難易度と通貨価格を組み合わせて,ハッシュレート,消費電力,電気料金からマイニング利益を計算します',
      // miniMiniCalc: 'ミニマイニング計算機',
      // miniMiniCalcExpress: 'ハッシュレート,難易度,通貨価格のみを考慮して,マイニング利益をすばやく計算します',
      // strat: '開始',
      // btcTool: 'CloverPoolプールバッチツール',
      // btcToolIntroduce: 'マイナーバックグラウンド構成のバッチ処理,便利で高速',
      //ダウンロード:'クリックしてダウンロード'
      btcSmartAgent: 'BTCスマートエージェント',
      agentExpress:
        '効率的で透明性のある<br/>単一のマイニングマシンが表示されます',
      agentExpressBanner:
        '効率的で透明性のある<i style="margin-right:20px"></i>単一のマイニングマシンが表示されます',
      bit: 'ビット',
      document: 'Windowsバージョンのヘルプドキュメント',
      poolApp: 'CloverPoolプールアプリ　フル機能アプリ',
      poolRealTime: 'プールとマイニングマシンのデータをリアルタイムで表示する',
      poolOpeation: 'マイニングマシンの削除,変更,その他の操作をサポートする',
      poolComming: 'プッシュアラーム機能が開始されました',
      poolDownlaod: 'クリックしてダウンロード',
      title: 'ツール - CloverPool',
      tools: 'ツール',
      fppsPps: 'FPPSと従来のPPS動的比較ツール',
      aboutFpps: 'FPPSは従来のPPSよりどこが優れていますか？ ',
      charts: '動的データ　一目瞭然',
      watchCharts: 'クリックして比較',
      miniCalc: 'マイニング計算機',
      miniCalcExpress:
        '難易度と通貨価格を組み合わせて,ハッシュレート,消費電力,電気料金からマイニング利益を計算します',
      miniMiniCalc: 'ミニマイニング計算機',
      miniMiniCalcExpress:
        'ハッシュレート,難易度,通貨価格のみを考慮して,マイニング利益をすばやく計算します',
      start: '開始',
      btcTool: 'CloverPoolプールバッチツール',
      btcToolIntroduce: 'マイナーバックグラウンド構成のバッチ処理,便利で高速',
      download: 'クリックしてダウンロード',
      ipToolsTitle: 'CloverPoolマイニングマシンIPバッチ変更ツール',
      ipToolsDes:
        'すべての系列のアリマイニングマシンをサポートし,占有されているIPを変更し,プールへの直接接続を構成します',
    },
    miners: {
      createGroup: '新しいグループを作成する',
      deleteGroupTitle: 'グループの削除',
      editGroupTitle: 'グループの編集',
      all: '全部',
      default: 'まだグループ化されていない',
      addGroup: '新しいグループを作成する',
      addGroupInfo:
        '注意:選択したマイニングマシンは自動的に新しいグループに追加されます',
      groupNameLabel: 'グループ名',
      addGroupEmpty: 'グループ名を空欄にすることはできません',
      groupRejected: 'リアルタイム拒絶率', // Real time rejection rate
      groupNameLimit: 'グループ名は3〜20文字である必要があります',
      inactive: '非アクティブ',
      active: 'アクティブ',
      dead: '失効',
      moveTo: '移動先',
      remove: '削除',

      allTooltip:
        '含める:アクティブなマイニングマシン（{アクティブ}）+非アクティブなマイニングマシン（{非アクティブ}）',
      inactiveTooltip:
        '計算結果を10分以上送信（共有）しなかったマイニングマシンの数。 ',
      activeTooltip: '正常に計算結果を送信（共有）するマイニングマシンの数',
      deadTooltip:
        '計算結果を24時間以上送信（共有）していないマイニングマシンの数',

      home: 'トップページ',
      miners: 'マイニングマシン',

      workerName: 'マイニングマシン名',
      Hashrate: 'ハッシュレート',
      Rejected: '拒否率',
      dailyHashrate: 'デイリーハッシュレート',
      firstShare: '最初の接続時間',
      LastShare: '最終送信時間',
      groupName: 'グループ名',

      delMillTitle: 'これらのマイニングマシン情報を削除してもよろしいですか？ ',
      delMillContext:
        'この操作では,選択したすべてのマイニングマシンが削除されます。注意して操作してください。 ',
      delMillFailed: '削除に失敗しました',

      delGroupTitle: '「{name}」グループを削除してもよろしいですか？',
      delGroupContext:
        'グループ内のすべてのマイニングマシンはグループ化されていないものに移動します',

      moveTitle: 'モバイルマイニングマシン',
      notChecked:
        'バッチ操作をサポートするためにマイニングマシンにチェックマークを付けてください',
      moveContext:
        'この操作により,選択したすべてのマイニングマシンが{groupName}グループに移動します',
      moveFaild: '現在のグループには移動できません',

      searchPlaceholder: 'マイニングマシンの名前を入力してください',
      reportTime: 'このレポートが生成された時間です',
      refresh: '更新',
      editGroupName: 'グループ名の変更',
      workListEmpty: 'このグループでマイニングマシンは検出されませんでした',
      exist: 'このグループはすでに存在します',
      goOldVersion: '古いバージョンに戻る',
      dataExport: `" {name} "グループのハッシュレートデータのエクスポート`,
      exportTip:
        'エクスポートするデータの時間間隔を選択してください。最大で30日間のデータしかエクスポートできません。エクスポートされるファイル形式はCSVです。 ',
      exportTip2:
        'その日のハッシュレートの統計時間は00:00-23:59であり,今日のハッシュレートの統計時間は00:00からエクスポートまでの時間です。 ',
    },
    minerInfo: {
      miners: 'マイニングマシン',
      hashrate: '単一マシンのハッシュレート',
      runningCondition: '実行状況',
      min: '分',
      hour: '{n}時間',
      hashrateAxis: 'ハッシュレート',
      rejected: '拒絶率',
      status: 'ステータス',
      lastShare: '最終送信時間',
      lastShareIP: '最近送信されたIP',
      hashrateChart: 'ハッシュレートチャート',
      realTime: 'リアルタイム',
      trans: {
        seconds: '秒',
        mins: '分',
        hours: '時間',
        ago: '前',
        ACTIVE: 'アクティブ',
        INACTIVE: '非アクティブ',
        DEAD: '失効',
      },
    },
    earningHistory: {
      title: '収益履歴 - CloverPool',
      earn: '收益履歴',
      Unpaid: '未払い',
      EarningsToday: '掘られたと推定',
      EarningsYesterday: '昨日の収益',
      Time: '採掘日',
      NetworkDifficulty: '困難',
      Earnings: '収益',
      Payment: '支払い時間',
      payWithOTCAmount: '{otcType}で収入を決済する:{amount}',
      Mode: 'モード',
      Address: 'アドレス',
      PaidAmount: '収入額',
      yesterday: 'UTC時間の前日の0：00〜24：00の収益を指します。',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          '支払い先住所が設定されていません,残高に計上されます。 ',
        PENDING_CHANGED_ADDRESS:
          '住所が変更されてから48時間後に支払いが開始されます。警告:もし本人以外によるアドレスの変更された場合は,アカウント（電子メール/携帯電話）が侵害されている可能性があります。別のメールアドレスまたは携帯電話番号を使用して新しいアカウントをすぐに登録し,マイニングマシンを新しいアカウントに切り替えてください。 ',
        PENDING_NOT_ENOUGH:
          '金額が設定したしきい値（デフォルト:limit）より少ない場合,残高に預け入れられます。 ',
        PENDING_POOL_SETTLE_FAULT:
          'プールが原因のため,この部分の金額は誤って発行されたものであり,今後は発行されません。 ',
      },
      depositeAmount: '預金残高',
      diffChg: '難易度の変更:',
      equalsPps: 'PPSに変換',
      Chg: 'その日の変更',
      status: '支払い状況',
      paid: '支払済',
      waitingPay: '支払い待ち',
      reason: '原因',
      fppsMoreEarn: '{mode}追加収入:',
      fppsContain:
        'PPSモードと比較して,{mode}モード収益は約{pps96}の増加分を獲得します',
      noEarningsInformation: '収益情報なし',
      earningDetail: '特典の詳細',
      Equivalent: '同等の金額　',
      extraProfits: '追加収入',
      autoSwap: 'オートスワップ',
      original: 'オリジナル',
      Swapped: '交換済み',
      swapping: '交換中',
      otcEarningTip:
        'スワップスマートプールモードの目標通貨収入は,Matrixport請求日の実際のスワップ為替レートに基づいて計算されます',
      originEarningTip:
        'オリジナルスマートプールモードの目標通貨収入は,請求日の午前8時のMatrixportのフラッシュ為替レートに基づいて計算されます',
      exportEarningsData: '収益データのエクスポート',
      exportTip:
        '最大180日間のデータをサポートでき、エクスポートされるファイル形式はXLSです。',
      originStatusTip:
        '収入はスワップ機能によって目標通貨収入に変換されており,詳細はスマートプールモードの収入ページから照会できます。',
      swappingTip:
        '現在の収入が交換されているか,収入が少ないために最低交換額に達していない',
      swappedTip:
        '収入はスワップ機能によって目標通貨収入に変換されており,詳細はスマートプールモードの収入ページから照会できます。',
      // upgrade TODO 收益改版新增
      settlementHashrate: '決済算力',
      addressRatio: 'アドレス比率',
      addressEarnings: '収入に対処する',
      paymentTime: '支払い時間',
      EarningsTodayTip:
        'UTC 0:00から現在まで、掘り出されたPPS収益の推定額は、実際の収益とは異なる場合があります。',
      TimeTip:
        'マイニング日はUTC時間です。たとえば、2020年1月1日は、UTC時間で2020年1月1日の00：00：00- 2020年1月2日の00:00:00を指します。',
      EarningsTip:
        '収益額は、「CloverPool決済センター」によって、収益分配方法、ネットワーク全体の難易度、決済計算能力、基本報酬のブロック、取引手数料報酬のブロック、マイニングプールレートなどの多くの要因に基づいて計算されます。 。リターン計算機で計算した理論上のリターンは偏っている可能性がありま',
      // upgrade TODO 邀请新增
      inviteEarning: 'リベート収入',
      inviteWorkerAccount: 'マイナーサブアカウント',
      inviteRebateRatio: '割戻し率',
      inviteRebateAmount: '割戻し額',
      rebateExportTip:
        '最大60日間のデータをサポートでき、エクスポートされるファイル形式はXLSです。',
    },
    poolStats: {
      title: 'プール統計 - CloverPool',
      poolStats: '統計',
      hashrate: 'ハッシュレート',
      coinType: 'コイン',
      miners: 'マイニングマシン',
      found: 'マイニング所得',
      hashrateChart: 'ハッシュレートチャート',
      blocksRelayed: 'ブロードキャストブロック',
      height: '高さ',
      time: '時間',
      blocks: 'ブロック',
      blockHash: 'ブロックハッシュ',
      ddaScore: 'DAA Score',
      reward: 'ブロック報酬',
      day: '日',
      region: 'ブロック地域',
      lucky: 'ラッキー值',
      btc: 'ビットコイン',
      ltc: 'ライトコイン',
      nmc: 'ドメインコイン',
      zec: 'ゼロコイン',
      bcc: 'ビットコインキャッシュ',
      bch: 'ビットコインキャッシュ',
      eth: 'イーサリアム',
      etc: 'イーサリアムクラシック',
      dcr: 'DCR',
      doge: 'ドージコイン',
      grin: 'GRIN',
      sbtc: 'スーパービットコイン',
      ubtc: 'ビットコインユニオン',
    },

    //プロテクター
    guardian: {
      expiredDeadline: '（:day自動削除）',
      guardianRemark:
        '子アカウント情報（ハッシュレート,マイニングマシンなどを含む）の全部または一部を他のユーザーと共有できます。そのユーザーは情報を表示することはできますが,変更することはできません」',
      createGuardian: '共有ユーザーを追加',
      remark: '備考:',
      user: 'ユーザー:',
      manage: '管理',
      status: '状態:',
      theGuardian: '共有アカウント:',
      creationDate: '作成日:',
      datePrevious: '最終使用日:',
      bounded: '関連済み',
      delete: '削除',
      deleteGuardianLink: '共有アカウントリンクの削除',
      createGuardianInvitation: '共有アカウントの招待リンクを生成する',
      guardianAccountManagement: '共有アカウント管理',
      modifyRemark: '備考名を変更します',
      validateRemarkEmpty: '備考名を空欄にすることはできません',
      enterRemark: '備考名を入力してください',
      noteInformation:
        'リマインダー:この名前は,異なる共有アカウントを区別するために使用されます。相手の連絡先情報やその他の情報を使用することをお勧めします。 ',
      confirm: '確認',
      create: '生成',
      cancel: '取消',
      guardianSuccessfullyCreated:
        '共有アカウントの招待リンクが正常に作成されました',
      copyLink: 'コピーリンク',
      authorized: '削除後,他のユーザーは子アカウントの管理権限を失います',
      noteSendGuardian:
        'リマインダー:このリンクを,マイニングマシンの管理が必要な信頼できる人に送信してください。このリンクは訪問を１回のみ許可するものです。もし相手が無効であると表示した場合は,リンクを再生成してください。 ',
      close: 'オフ',
      subaccount: '子アカウント',
      coinType: 'コイン',
      region: '地域',
      guardianStatus: '共有ステータス',
      guardianSelectExpress:
        '共有ステータスの切り替えにより,他のユーザーが管理できる子アカウントを自由に選択できます（デフォルト設定では,すべての子アカウントにアクセスできます）',
      invitTitle: '他の人と共有する',
      acceptTitle: '他の人が共有することを受け入れる',
      lostPower: '削除すると,相手の子アカウントの管理権限が失われます。',
      show: '表示',
    },
    //保護者の招待リンクを連携します
    guardianBind: {
      bindingGuardianInvitation: '共有アカウントの招待リンクを連携します',
      advance: '高級',
      guardianAccountManagement: '共有アカウント管理:',
      ConfirmGuardianBind: 'アカウントの保護と連携を確定します',
      remark: '備考名',
      enterRemark: '備考名を入力してください',
      suggestName:
        '統一された管理を容易にするために,相手の名前を使用することをお勧めします。入力しない場合は,デフォルトで相手の携帯電話番号またはメールアドレスが使用されます。 ',
      guardian: '連携',
      cancel: '取消',
      confirm: '確定',
      manageView: 'すべての子アカウント維持管理しますか？ ',
      suggestUsingName:
        '統一された管理を容易にするために,相手の名前を使用することをお勧めします。入力しない場合は,デフォルトで相手の携帯電話番号またはメールアドレスが使用されます。 ',
      SMS: '携帯電話',
      mail: 'メールボックス',
      code: '{mode}認証コード',
      send: '取得',
    },
    account: {
      subAccountManage: '子アカウント管理',
      currentAccount: 'アカウント',
      locationRegion:
        '現在,<strong>{coin}</strong> <strong>{region}</strong>サーバーを使用しています',
      locationEx:
        '各子アカウントは固定の通貨と地域に対応します。一度作成すると,通貨と地域を変更することはできません。他のニーズがある場合は,新しい子アカウントを作成できます。 ',
      general: '一般的な管理',
      advance: 'プロフェッショナルな管理',
      create: '新しい子アカウント',
      algorithm: 'アルゴリズム',
      oneButtonSwitch: '一括切替',
      addOthersCoins: '他のアルゴリズム通貨を追加',
      supportCoins: 'サポート',
      hiddenSubAccounts: '隠れ子アカウント管理',
      hashAlert: '正常に機能しています',
      viewHideSubAccount: '非表示の子アカウントを表示',
      addSubAccount: '子アカウントの追加',
      recover: '復元',
      latestAlert: {
        hashrate: 'ハッシュレート {symbol} {expect} {unit}',
        miners: 'マイニングマシンの数 {symbol} {expect} 台',
      },
    },
    settings: {
      subAccount: '子アカウント',
      currency: 'コイン',
      region: '地域',
      walletAddressAndMinimumPayment: '受信アドレス:',
      walletAddress: '受信アドレス',
      shareData: 'アカウント共有',
      shareDataTip:
        'サブアカウント情報の全部または一部を、表示はできるが変更できない他のユーザーと共有できます。他の人のサブアカウント情報の全部または一部を読み取り専用モードで受け取ることもできます。',
      alertSettings: 'アラート設定',
      mywatcher: 'ウォッチャー設定',
      apikey: 'API KEY',
      payWithBtc: 'BTCで清算',
      //wallet address & minimum payment
      setAddress: '{coinType}支払いアドレスを設定してください',
      address: '受信アドレス',
      createAddress: '新しいアドレス',
      modifyAddress: 'アドレスの変更',
      modify: '修正',
      minPay: 'しきい额',
      lastUpdateTime: 'アドレス変更時間',
      updateBitconAddressTip:
        '注意:資金の安全のため,この操作後48時間支払いが停止され,収益は残高となり,48時間後の最初の決済日に支払われます。 ',

      old: '旧アドレス',
      new: '新しいアドレス',
      continue: '次へ',
      addAddress: '入力',
      enterCode: '確認コードを入力してください',
      newBit: 'あなたの新しいアドレス',
      way: 'この変更を確認するための確認コードを送信します。確認方法を選択してください:」',
      sendVerifyCode: '確認コードが{contactType}に送信されました',
      SMS: '携帯電話',
      mail: 'メールボックス',
      mailVia: '確認コードをメールボックスに送信',
      smsVia: '確認コードを携帯電話に送信',
      errorLater: '後でもう一度やり直してください…',
      updateSuccess: 'アドレスの変更に成功しました',
      //マルチアドレス
      multAddress: {
        tag: ' マルチアドレス',
        addMultAddress: 'アドレスを追加',
        adjustProportion: '比率を調整する',
        adjustMinPay: '最低支払い額を調整する',
        address: 'アドレス',
        proportion: '比率',
        remark: '備考',
        lastUpdateTime: '変更時間',
        operation: '操作',
        emptyText: '支払い先住所が追加されていません',
        emptyTextForMult: 'データなし,最大20個のアドレスを追加できます',
        cancel: '取消',
        nextStep: '次へ',
        delete: '削除',
        modify: '修正',
        newAddressPlaceholder: '新しいアドレス',
        addressMaxCountTip:
          '現在,子アカウントは最大{maxCount}のアドレスを追加できます。さらに多くのアドレスをサポートする必要がある場合は,カスタマーサービスsupport@connectbtc.comにお問い合わせください。 ',
        updateBitconAddressTip:
          '資金の安全のため、この操作後48時間以内に支払いが停止され、48時間後の最初の決済日に売掛金が支払われます。',
        emptyRemark: '備考は追加されていません',
        noAddressForAdjust:
          'アドレスはまだ追加されていません。比率またはしきい値を調整する前に,アドレスを追加してください。 ',
        balanceMoveTip:
          '現在のサブアカウントの未払いの暗号通貨は「新しいアドレス1」に転送されます。操作を確認してください。',
        confirmModalTitle: 'セキュリティ検証',
        authentication: '認証',
        operationConfirm: '操作の確認',
        addAddressConfirmTip: '{count}の支払いアドレスを追加しています',
        modifyProportionConfirmTip: '支払いアドレスの比率を調整しています',
        deleteAddressConfirmTip: 'アドレスを削除しています<br/> {address}',
        selectBalanceMoveAddress: '継承アドレスを選択してください',
        selectBalanceMoveAddressTip:
          '削除されるアドレスの未払いの暗号通貨は{balance}{coinType}です。 この操作の後、24時間以内に上記で選択した新しいアドレスに転送されます。 ',
        cannotDeleteAddress: 'このアドレスを削除できません',
        deleteAddressRule: '収集率が0.00％のアドレスのみを削除できます。 ',
        // deleteAddressRuleDetail:
        // '1.収集率が0.00％に等しい; <br/> 2.同じ子アカウントのスマートプールマイニングアドレスがこのアドレスとして設定されていません。 ',
        // usedBySmart:
        // '現在の子アカウントのスマートプールマイニングは現在のアドレスを選択します。この変更により,スマートプールマイニングアドレスも同時に変更されます。 ',
        operationSuccess: '操作が成功しました',
        validate: {
          emptyAddress: '新しいアドレスを入力してください',
          emptyAddressForUpdate: 'アドレスを空欄にすることはできません',
          emptyProportion: 'アドレスを入力してください',
          emptyProportioAfter: 'の比率',
          ProportionInvalidate:
            '各アドレスの比率は0％以上である必要があり、すべての比率の合計は100％である必要があります',
          maxCount: '各子アカウントは最大20個のアドレスを設定できます',
          duplicateAddressPrefix: '同じアドレスは一度だけ追加できます,アドレス',
          duplicateAddressSuffix: '複数回追加されました。 ',
          limitAddress: 'This type of address is not supported at this time',
        },
      },

      //min pay
      currentMinPay: '現在のしきい額:',
      minPayAllSubAccount:
        'すべてのビットコイン（BTC）子アカウントは同じ最小支払い額を使用します',

      //Share to watcher
      watcher: '観察者',
      availableWatchers: '現在利用可能',
      watcherExpress:
        '*観察者は,以下のリンクを介してデータにアクセスできますが,アカウントを操作または変更することはできません。 ',
      createWatcher: '新しい観察者を作成する',
      modifyWatcher: '権限の編集',
      authoritySettings: '権限設定',
      setWatcherName: '観察者のための備考名を設定します',
      watcherName: '観察者備考名',
      watcherNameTip: '文字数制限2〜20',
      notEmptyWatcherNote: '観察者備考名を空欄にすることはできません',
      tips: '注意:',
      tip_dashboard:
        '1.「ダッシュボード」には、「未払い」や「有料」などの収益情報は含まれません。',
      tip_earning:
        '2.「収益」には、各住所の「未払い」や「有料」などの情報が含まれます。',
      moreSettings: '詳細設定',
      guardianRemark:
        '子アカウント情報（ハッシュレート,マイニングマシンなどを含む）の全部または一部を他のユーザーと共有できます。そのユーザーは情報を表示することはできますが,変更することはできません」',
      guardian: 'アカウント共有',
      shareAccountsSetting: 'アカウント共有',
      accessKey: 'Access Key',
      generateAK: 'Access Key生成',
      puid: 'PUID',
      apiDoc: 'APIドキュメント',
      confirmDeleteWatcher: 'この観察者アカウントを削除してもよろしいですか？',
      watcherAuths_Dashboard: 'ダッシュボード',
      watcherAuths_Dashboard_Tip: '（収益情報を除く）',
      watcherAuths_Miners: 'マイニングページ',
      watcherAuths_Earnings: '収益情報',
      watcherAuths_GetCoin: 'ギフトコイン',

      // Alert setting
      alertItem: 'アラームアイテムの設定',
      alertContacts: 'アラーム連絡先',
      hashrateAlert: 'ハッシュレートアラート',
      minerAlert: 'マイニングマシン数のアラーム',
      alertFrequency: 'アラーム頻度',
      alertFrequencyTip:
        'ポリシー上の理由により,一部の国および地域の携帯電話のSMSアラームの頻度は,24時間ごとに1回と決められています。ご注意ください。 ',
      warnMeWhenHashrate: 'ハッシュレート警戒ライン≤',
      warnMeWhenActiveMiner:
        'アクティブなマイニングマシンの数の警戒ラインcordon≤',
      dontWarnMeTwice: '2つのアラーム間の最小間隔',
      hours: '時間',
      apply: '適用',
      operateFailed: '操作失败!',
      contactsList: '連絡先リスト',
      cancel: '取消',
      ok: '确定',
      save: '保存',
      reset: 'リセット',
      newContact: '新しい連絡先',
      editContact: '連絡先の編集',
      deleteContact: '連絡先の削除',
      confirmDeleteContact: 'このアラーム連絡先を削除してもよろしいですか？',
      name: '呼称/備考',
      phone: '電話',
      email: 'メールボックス',
      validateAlert: {
        nameEmpty: '呼称/備考を空欄にすることはできません',
        phoneRegionFormat: '国際市外局番の形式が正しくありません',
        phoneFormat: '電話フォーマットが正しくありません',
        emailFormat: 'メールボックスのフォーマットが正しくありません',
        contactEmpty: 'メールアドレスと電話番号を1つ入力する必要があります',
      },
      max_email_contacts: '最大10件のメールアドレスをサポート',
      max_phone_contacts: '最大5つの電話連絡先をサポート',
      enabled_alert_need_hashrate:
        'ハッシュレートが検出されないため,アラームをオンにできません。 ',
      alertItemUpdateSuccess: 'アラームアイテムが正常に設定されました',

      done: '提出',

      okay: '确定',
      emptyAddress: 'アドレスを空欄にすることはできません',
      invalidAddress: '間違ったアドレス',
      emptyCode: '確認コードを空欄にすることはできません',
      wrongCode: '確認コードエラー',
      emailVia: 'メールを送信する',
      textVia: 'SMSを送信する',
      help: 'ヘルプ',

      contact: '連絡方法:',
      close: 'オフ',
      receivedress: '受信アドレス:',

      todayUpdateMinPay:
        '今日からのマイニング収益の最小支払い額が次のように変更されました:{minPay}',
      updateAddress: 'アドレス変更時間:',
      // pay with btc
      payWithBtcTip:
        '* BTCでの決済を有効にした後,マイニング収入は毎日決まった時間にビットコインに変換され,収入記録に反映されます。そして以下の独立したBTC決済アドレスに支払われます。通常は毎日午前12時です。特別な状況は別途通知されます。 ',
      convertedCurrency: 'コイン交換: ',
      setBtcAddressTip: 'BTC支払いアドレスを設定してください',
      btcAddress: 'BTCアドレス: ',
      ConversionRate: '参照為替レート',
      convert: '変換',
      getValue: '確定利益',
      ConversionTip:
        '*為替レートは参照用であり,実際の決済結果と異なる場合があります。',
      lastUpdate: '最后更新于: ',
      on: 'ON',
      off: 'OFF',
      turnOnPaywith: 'BTCでの支払いをオンにしますか？',
      turnOffPaywith: 'BTCでの支払いをオフにしますか？',
      payOn: 'オン',
      payOff: 'オフ',
      resend: '再送信',
      send: '認証コードを送信',
      resendTip:
        '認証コードを受け取っていない場合は,しばらく待つか,もう一度試してください',

      // スイッチで切り替え One Switch
      oneSwitch: {
        attention: 'ご注意下さい',
        optionCurrentMode: '切り替えモードを選択してください',
        switch: 'ハッシュレートを{destMode}に切り替えようとしています',
        switchAnotherMining: '{destMode}モードに切り替えています...',
        ethSwitchWarning:
          '警告:マイニングマシンがDAGの切り替えをサポートしていない場合,収益はゼロになります。正常な状態に戻すには,マイニングマシンを手動で再起動する必要があります。 （アリマイニングマシン E3はそれをサポートしていません。よってETCからETHに切り替えるには手動で再起動する必要があります） ',
        ethSwitchWarningLinkTxt: '詳細はクリックしてください',
        hashsHaveSwitched:
          'ハッシュレートが（destMode）マイニングに切り替えられました;',
        checkSubAccount:
          '子アカウント{subAccountName}-{subAccountRegionNode}を確認してください',
        enter: '入力',
      },
      //スマートプールの支払いアドレス
      smartAddress: {
        // autoSwapSettlement: 'オートスワップ',
        original: 'オリジナル',
        autoTip: 'スマートプールの収益通貨は,決済の目標通貨と毎日交換されます',
        originalTip:
          'スマートプールの収入はスマートプールによってサポートされているBTC,BCH,BSVで決済されます',
        originalCurrency: 'ソース通貨',
        targetCurrency: 'ターゲット通貨',
        earningsInSmartPool: 'スマートプール収益',
        activated: 'オン',
        unactivated: 'オフ',
        modifyTip:
          'スマートプールのスワップ決済のターゲット通貨は同じ子アカウント下の同じ通貨のアドレスに関連付けられ,一致しています',
        autoHistory: '過去の利益をスワップしますか？ ',
        notice1:
          'セキュリティ上の理由から,アドレスが変更されてから48時間以内に支払いはおこなわれず,収入は残高として計上され,48時間後の最初の決済日に支払われます。 ',
        notice2:
          'スワップ機能をオンにすると,スマートプールの採掘収益は同日の決済で自動的に目標通貨に変換され,[確認]をクリックすると{fundLink}に同意したものと見なされます。 ',
        notice3:
          'スマートプールモードのアドレスは,同じ子アカウントの通貨収入アドレスに関連付けられ,一致します（複数のアドレスが設定されている場合は,最初のアドレスと一致します）」',
        originalNotice1:
          'スマートプールモードのアドレスは,同じ子アカウントの通貨収入アドレスに関連付けられ,一致します（複数のアドレスが設定されている場合は,最初のアドレスと一致します）」',
        currentPayoutMode: '現在の支払いモード:{mode}',
        FundLink: `《 CloverPoolファンドスワップ承認契約》`,
        originBtn: '元の通貨決済をオンにする',
        otcBtn: 'スワップ決済をオンにする',
        otcSuccess:
          'スマートプールモードのスワップ決済機能がオンになり,支払いの通貨としてBTCが使用されます。 ',
        otcModalNotice2: `スワップ収益履歴を有効にした後,アカウントの収益履歴がスワップに入ります。スワップ収益履歴が有効になっていない場合,最新の決済サイクルの収入のみがスワップに入ります。 `,
        originSuccess:
          'スマートプールモードの元の通貨決済機能がオンになり,実際のマイニング通貨が収益支払い通貨として使用されます。 ',
        // 2021-06-04の新機能
        mode_otc: 'オートスワップ',
        mode_origin: 'オリジナル',
        settlementMode: '決済モード',
        settlementModeDescription: '決済モードの説明',
        originDescription:
          '元の通貨決済:決済は実際のマイニング通貨であり,通貨の種類はBTC,BCH,およびBSVです。 ',
        autoSwapDescription:
          'スワップ決済:まずスマートプールアルゴリズムに従ってBTC / BCH / BSV の3種類の動的マイニングを実行し,最後に決済のためにそれらをBTCに変換します。 ',
        settlementSwitch: '決済モード切替',
        originalSettlement: 'オリジナル',
        autoSwapSettlement: 'オートスワップ',
        sha256SmartPoolCurrency: 'Sha256スマートプール通貨',
        currency: 'コイン',
        address: 'アドレス',
        addressRules: 'アドレスルール',
        switchAddress: 'アドレスの切り替え',
        noAddressTip:
          '同じ子アカウントの{coin}通貨にアドレスが追加されていません',
        noAddressSelected: 'アドレスが選択されていません',
        selectFrom: '同じ子アカウントの{coin}アドレスリストから選択',
        settingTip:
          'リマインダー:「スマートプールマイニング支払いアドレス」と「同じ子アカウントの単一通貨マイニングアドレス」を区別する場合は,単一通貨アドレス管理に新しいアドレスを追加し,支払い比率を０に変更してください。それからここに戻って,新しいアドレスに切り替えます。 ',
      },
    },
    homepage: {
      btcSmartAgent: 'BTCスマートエージェント',
      agentExpress:
        '効率的で透明性のある＆emsp;＆emsp;＆emsp;マイニングマシンが表示されます',
      bit64: 'Win7かそれ以降の64ビット',
      bit32: 'WinXP32ビット',
      document: 'Windowsバージョンのヘルプドキュメント',
      accessLinuxVersion: 'Linuxバージョンのインストール直接アクセス',
      CuttingEdgeTechnology: '最先端のコアテクノロジー',
      latestArchitecture: '最新のオリジナルアーキテクチャ<br/>全てを俯瞰する',
      openSourceCode: 'コードオープンソース<br/>オープンで公正',
      highTestPressure: '超高圧テスト<br/>岩のように固い',
      lowOrphanedRate: '超低孤立率<br/>稲妻のように速い',
      parallelAgent: '全く新しいサポートソフトウェア<br/>透過エージェント',
      StandardsServices: 'マイニングサービスの新基準',
      customerHotline: 'ホットライン',
      highHashrateConsultation: '大口顧客投資促進',
      professionalCustomerService:
        '本物のプロフェッショナルカスタマーサービスを備えたプール',
      bestVipServer: '業界で最も高仕様のVIPサーバー',
      oneToOne: '業界のトップ研究者の1対1のケーススタディ',
      appAvailable: 'プールアプリを使用すると,寝っ転がってでも管理できます',
      earningsTimelyEveryday: '雨風関係なく,時間通りにお金を稼げます',
      transnationalTeamOperation: '多国籍チームがサポートする最先端プール',
      currencies: 'コイン',
      dailyEarnings: 'デイリー收益',
      hashrate: 'ハッシュレート',
      hashrateChart: 'ハッシュレートチャート',
      explorer: 'ブラウザ',
      prices: 'コイン価格',
      earningsModels: '收益モデル',
      minPayment: '最小支払金额',
      help: 'ヘルプ',
      notePaytime: '毎日0：00-8：00（UTC）は昨日の収益を支払います',
      yuanKwhElectric: '円/kW·h(電気代)',
      todayEarnings: '本日の收益:',
      piece: '台',
      signIn: 'サインイン',
      signUp: '登録',
      power: '電力消費',
      hashrateProfitEst: '参考ハッシュレート収益',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst: '参考マイニング収益',
      fee: '費用率',
      desc: '世界をリードするマルチ通貨の包括的なサービスプール',
      tutorial: 'ガイド',
      diff: '現在の難易度',
      nextDifficultyEstimated: '次回の予測難易度',
      dateToNextDifficulty: '次の難易度までの残り時間',
      Algorithm: 'アルゴリズム',
      backup: 'バックアップ',
      whyMe: {
        title: 'CloverPool(formerly BTC.com) を選ぶ理由',
        leadingTechnology: 'リーディングテクノロジー',
        convenientManagement: '便利設定',
        revenueAssurance: '收益保証',
        professionalServices: '専門的サービス',
        desc1: `オープンソースコードで,無料のマイニングマシン管理ソフトウェアであり,公式バージョンは,収益,ブロックチェーンデータ,および技術サービスプロバイダーが大幅に増加します。 `,
        desc2:
          'シンプルなアプリとウェブサイドの操作,携帯電話番号/メールアドレスの簡単な登録,独立したアラーム機能,収入アドレス,マイニングマシンのグループ化,共有データ観察者リンク。 ',
        desc3:
          '真に透明性のあるマイニングデータ,最先端FPPS収益モデル,強力な決済センター,時間厳守支払い。 ',
        desc4:
          'CloverPool(formerly BTC.com) には、プロのカスタマー サービス チーム、最高水準のカスタマイズされた VIP サービスがあり、業界トップの研究者が直接ケースを分析します。',
      },
      app: {
        title: '(formerly BTC.com) App',
        description: '世界をリードするマルチ通貨統合プール',
        property1:
          'マルチ通貨マイニング,マルチ子アカウント管理,観察者リンク共有の簡単な管理をサポート',
        property2:
          '包括的な通貨データがリアルタイムで表示され,マイニングダイナミクスをより素早く把握します',
        property3:
          '新しいインタラクティブインターフェイス,使用感がよりスムーズに',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: 'ETHの決済モードはFPPS / PPS +の２つのモードがあり,現在のモードはFPPSモードです。プラットフォームは,オンチェーントランザクションコスト,当プールのハッシュレート,市場の状況,ユーザーの収入などを総合的に考慮し決済モードを調整します。 ',
            'PPS+':
              'ETHの決済モードはFPPS / PPS +の２つのモードがあり,現在のモードはPPS +モードです。プラットフォームは,オンチェーントランザクションコスト,当プールのハッシュレート,市場の状況,ユーザーの収入などを総合的に考慮し決済モードを調整します。 ',
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL,LKY,PEP',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips:
        'ETCのみをマイニングする機器は、このマイニングアドレスに接続します',
      zilMinerAddressTips:
        'このマイニングアドレスにはETCとZILのデュアルマイニング機器が接続されています',
    },
    advanceAccount: {
      title: '子アカウント管理 - CloverPool',
      all: '全部のコイン',
      unit: 'ユニット',
      subAccount: '子アカウント',
      coin: 'コイン',
      hashrate: 'ハッシュレート',
      allMiners: '全てのマイニングマシン',
      guard: '備考名',
      activeMiners: 'アクティブ',
      inactiveMiners: '非アクティブ',
      deadMiners: '失効',
      region: '地域',
      more: 'もっと見る',
      setting: '設定',
      totalHashrate: '総ハッシュレート',
      search: '検索',
      status: 'ステータス',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: '即日,CloverPoolプールでマイニング（ビットコインキャッシュ,ビットコイン）を行い,ドメインコイン（NMC）とエラストス(Elastos)を無料で提供します。 ',
        bch: '即日,CloverPoolプールでマイニング（ビットコインキャッシュ,ビットコイン）を行い,ドメインコイン（NMC）とエラストス(Elastos)を無料で提供します。 ',
        ltc: '即日,CloverPoolプールでマイニング（ライトコイン LTC）を行い,同時にドッグコイン（Doge）を無料で提供します。これはPPLNSによって決算されます。 ',
        bcha: `<p>BCHA（BCHABC）はBCHから派生したコインで,関連情報は以下からご覧になれます <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a>。</p>
          <p><b>引出時間:</b><br/>2020年12月7日午前08:00 から 2021年1月7日午前08:00 (シンガポール時間)
          </p>`,
      },
      beta: 'ベータ版',
      whatsThis: 'これは何ですか？',
      address: 'アドレス:',
      newAddress: '新しいアドレス',
      addressComplete:
        '収入が失われないようにするために,できるだけ早く無料のコインを受け取るアドレスを入力してください。 ',
      bchaAddressComplete:
        '2021年1月7日以降,アドレスを入力しなかったユーザーのBCHAはクリアされ,再発行されません。 収入が失われないように,お早めに無料コインを受け取るアドレスをご記入ください。 ',
    },
    watcher: {
      expired: '観察者権限期限切れ',
      incorrect: '観察者リンクエラー',
    },
    activity: {
      pointsToday: '今日のポイント',
      pointsSum: '累計ポイント',
      ranking: 'ランキング',
      earnings: '収益',
    },
    minerRanking: {
      title: 'マイニング収益ランク',
      electric: '電気代/日',
      dailyEarning: 'デイリー收益',
      priceShutdown: 'クローズ価格',
      ALL: '全部',
      CNY: '人民元 CNY',
      USD: '米ドル USD',
      desc: '收益降順',
      asc: '收益昇順',
      electricPercent: '電気代比率',
      searchTip: 'マイニングマシン入力',
      updated: 'で更新',
      coin: 'コイン',
      emptyShow: '条件に一致するマシンがありません',
      rank: 'ランキング',
      miner: 'マイニングマシン',
      power: '電力消費',
      hashrate: 'ハッシュレート',
      rev24h: 'デイリー産出',
      energy: '電気代支出',
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
      incomeWithGiftCoin: 'Include gift coin',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
